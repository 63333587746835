import $ from 'jquery';

// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

console.log('script.js');

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as ajaxModal from './scripts/ajaxModal';
app.modules.ajaxModal = ajaxModal.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as countUp from './scripts/countUp';
app.modules.countUp = countUp.initInScope;

import * as scrollAnimation from './scripts/scrollAnimation';
app.modules.scrollAnimation = scrollAnimation.initInScope;

import * as slider from '@elements/slider';
app.modules.referencesSlider = slider.createInitInScope('.js-references-slider', {
    prevArrow: '<button type="button" class="slick-prev" aria-label="prev"><span class="icon icon-left-arrow"></span></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="next"><span class="icon icon-right-arrow"></span></button>',
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        },
    ]
});

import { showCookieBar } from "@elements/cookie-permissions";
if(_config.cookieBar.disable === false) {
    if (!_config.admin && !_config.editmode) {
        showCookieBar();
    }
}

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);