"use strict";

import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    add(
        $scope.find('.js-fade-in'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = window.innerHeight / 2;

    return {
        transform: Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 120 + 'px'
    };

}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    // element.style.opacity = progress;
    element.style.transform = 'translate3d(0, '+ progress.transform +', 0)';
}
