"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";
import fetch from '@elements/fetch';

export function initInScope ($scope) {
    let $btn = $scope.find('.js-ajax-modal__btn');

    $btn.on('click', function (evt) {
        let $result = $('body').find('.js-ajax-modal__content');

        evt.preventDefault();
        $result.html('');

        let url= $(this).attr('data-href');

        fetch(url, {
            headers: {Ajax: 1}
        })
            .then(response => response.text())
            .then(function (result) {
            $result.html(result);
            //initModulesInScope($result);
        }).catch(function(a){
            console.warn(a);
            alert('Es ist ein Fehler aufgetreten. Versuchen Sie es später nochmals.')
        });
    });
}